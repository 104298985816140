// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@import "../node_modules/bootstrap/scss/bootstrap";

@include mat.core();

$mat-blue: (
    50: #e3f6f8,
    100: #b8e9ee,
    200: #8ddae5,
    300: #67cbdd,
    400: #4ec0da,
    500: #38b5d7,
    600: #2ea6ca,
    700: #2194b8,
    800: #1f82a5,
    900: #126385,
    A100: #88c9ff,
    A200: #55b1ff,
    A400: #229aff,
    A700: #098eff,
    contrast: (50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #ffffff,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$mat-orange: (
    50: #fdecee,
    100: #fad0d3,
    200: #e79f9d,
    300: #db7c78,
    400: #e85640,
    500: #e85640,
    600: #d94d3e,
    700: #c74438,
    800: #ba3f32,
    900: #aa3728,
    A100: #ffffff,
    A200: #ffddda,
    A400: #ffada7,
    A700: #ff958e,
    contrast: (50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #ffffff,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$mat-red: (
    50: #ffeaed,
    100: #ffccce,
    200: #fa9792,
    300: #f36e67,
    400: #fc4a3e,
    500: #ff371a,
    600: #f32a1d,
    700: #e11b17,
    800: #d40e0e,
    900: #c60000,
    A100: #ffeeee,
    A200: #ffbbbb,
    A400: #ff8888,
    A700: #ff6e6e,
    contrast: (50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #ffffff,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette($mat-blue, 900);
$theme-accent: mat.define-palette($mat-orange, 500);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette($mat-red, 800);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((color: (primary: $theme-primary,
                accent: $theme-accent,
                warn: $theme-warn,
            ),
            typography: mat.define-typography-config($font-family: '"Nunito", sans-serif'),
        ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

$base_size: 16;
$login_form_orange: #ec8e8e;
$login_form_blue: #71a9e6;
$family: "Nunito", sans-serif;
$white: #ffffff;
$cyan-blue: #387493;
$primary-color: #126385;
$full: 100%;

:root {
    --mat-paginator-container-background-color: rgba(0, 0, 0, 0);
    --mat-table-header-headline-color: rgba(255, 255, 255, 0.87);
    --mat-sidenav-content-background-color: rgba(255, 255, 255, 0);
    --mdc-checkbox-unselected-icon-color: rgb(135 178 197);
    --mdc-filled-text-field-container-color: rgba(0, 0, 0, 0);
    --mdc-checkbox-unselected-hover-icon-color: #0b4056;
    --mdc-checkbox-unselected-pressed-icon-color: #126385;
    --mdc-checkbox-unselected-focus-state-layer-color: #126385;
    --mdc-checkbox-unselected-hover-state-layer-color: #126385;
    --mdc-checkbox-unselected-pressed-state-layer-color: #126385;
    --mdc-checkbox-unselected-focus-icon-color: #0b4056;
    --mdc-typography-button-letter-spacing: 0;
    --mdc-protected-button-label-text-color: #0b4056;
    --mat-tab-header-with-background-foreground-color: #c9efff;
}

.mat-mdc-header-row {
    background-color: #126385 !important;
}

mat-tab-header.mat-mdc-tab-header {
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 0 3px 8px -2px rgba(123, 123, 123, 0.41), 0 3px 4px 0 rgba(195, 195, 195, 0.05), 0 1px 8px 0 rgba(239, 239, 239, 0.09);
}

.mat-mdc-menu-item .mat-icon {
    margin-right: 0.75rem;
    font-size: 16px;
    width: 16px;
    height: 16px;
}

.mat-mdc-form-field-focus-overlay {
    background: rgba(0, 0, 0, 0);
}

.addEditPermissions mat-bottom-sheet-container {
    width: 100%;
    max-width: inherit !important;
    height: 100vh;
    max-height: inherit !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

* {
    box-sizing: border-box;
}

.padding_0 {
    padding: 0;
}

.margin_0 {
    margin: 0;
}

.weight_300 {
    font-weight: 300;
}

body {
    font-family: $family;
    font-size: $base_size + 0px;
    @extend .padding_0;
    @extend .margin_0;
}

h1 {
    font-size: $base_size + 20px;
}

h2 {
    font-size: $base_size + 12px;
}

h3 {
    font-size: $base_size + 8px;
}

h4 {
    font-size: $base_size + 4px;
}

label {
    margin: inherit;
}

.login_form {
    --mdc-outlined-text-field-outline-color: #3bb1e4;
    --mdc-outlined-text-field-focus-outline-color: #3bb1e4;
    --mdc-outlined-text-field-focus-label-text-color: #3bb1e4;
    --mdc-outlined-text-field-label-text-color: #3bb1e4;
    --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-error-caret-color: rgba(232, 86, 64);
    --mdc-outlined-text-field-error-focus-label-text-color: rgba(232, 86, 64);
    --mdc-outlined-text-field-error-label-text-color: rgba(232, 86, 64);
    --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
    --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-error-focus-outline-color: rgba(232, 86, 64);
    --mdc-outlined-text-field-error-hover-outline-color: rgba(232, 86, 64);
    --mdc-outlined-text-field-error-outline-color: rgba(232, 86, 64);
    --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-color: #fff;

    .mat-mdc-raised-button[disabled][disabled] {
        --mdc-protected-button-disabled-container-color: rgba(232, 86, 64);
    }

    .mat-mdc-form-field-error {
        color: rgba(232, 86, 64) !important;
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        color: rgba(255, 255, 255, 1) !important;
    }

    input::placeholder {
        color: #71a9e6 !important;
    }

    .mat-mdc-form-field {
        font-family: "Nunito", serif !important;
    }

    .mat-mdc-button.mat-mdc-button-base,
    .mat-mdc-raised-button.mat-mdc-button-base,
    .mat-mdc-unelevated-button.mat-mdc-button-base,
    .mat-mdc-outlined-button.mat-mdc-button-base {
        height: 48px;
    }

    .mat-mdc-form-field-error {
        display: block;
        position: absolute;
        bottom: -25px;
    }

    .mat-error {
        color: #ec8e8e;
    }

    .mat-form-field-appearance-legacy {
        .mat-form-field-label {
            color: #71a9e6;
            font-weight: 300;
            font-family: "Nunito", sans-serif;
        }

        .mat-form-field-underline {
            background-color: #71a9e6;
        }

        &.mat-focused {
            .mat-form-field-label {
                color: #ffffff;
                font-weight: 300;
                font-family: "Nunito", sans-serif;
            }

            .mat-form-field-underline {
                background-color: #ffffff;
            }

            .mat-form-field-ripple {
                background-color: #ffffff;
            }
        }
    }

    .mat-form-field.mat-form-field-invalid {
        .mat-form-field-label {
            color: rgba(232, 86, 64);
        }

        .mat-form-field-ripple {
            background-color: rgba(232, 86, 64);
        }

        .mat-form-field-underline {
            background-color: rgba(232, 86, 64);
        }

    }

    .mat-icon {
        color: #71a9e6;
    }

    input {
        color: #ffffff;
    }

    .mat-input-element {
        caret-color: #ffffff;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: #3bb1e4;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
        border-color: #71a9e6;
    }

    .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: rgba(232, 86, 64);
    }

    .mdc-text-field--invalid {
        input::placeholder {
            color: rgba(232, 86, 64) !important;
        }
    }

    .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
        border-color: rgba(232, 86, 64);
    }
}

.forgot_form {

    --mdc-outlined-text-field-input-text-color: #fff;

    .mat-mdc-form-field-error {
        color: rgba(232, 86, 64) !important;
    }

    .mat-mdc-raised-button[disabled][disabled] {
        --mdc-protected-button-disabled-container-color: rgba(232, 86, 64);
    }

    input::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
    }

    .mat-mdc-form-field {
        font-family: "Nunito", serif !important;
    }

    .mat-mdc-button.mat-mdc-button-base,
    .mat-mdc-raised-button.mat-mdc-button-base,
    .mat-mdc-unelevated-button.mat-mdc-button-base,
    .mat-mdc-outlined-button.mat-mdc-button-base {
        height: 48px;
    }

    .mat-mdc-form-field-error {
        display: block;
        position: absolute;
        bottom: -25px;
    }

    .mat-error {
        color: #ec8e8e;
    }

    .mat-form-field-appearance-legacy {
        .mat-form-field-label {
            color: #ffffff;
            font-weight: 300;
            font-family: "Nunito", sans-serif;
        }

        .mat-hint {
            color: rgb(240, 152, 138);
        }

        .mat-form-field-underline {
            background-color: #ffffff;
        }

        &.mat-focused {
            .mat-form-field-label {
                color: #ffffff;
                font-weight: 300;
                font-family: "Nunito", sans-serif;
            }

            .mat-form-field-underline {
                background-color: #ffffff;
            }

            .mat-form-field-ripple {
                background-color: #ffffff;
            }
        }
    }

    .mat-form-field.mat-form-field-invalid {
        .mat-form-field-label {
            color: #ec8e8e;
        }

        .mat-form-field-ripple {
            background-color: #ec8e8e;
        }

        .mat-form-field-underline {
            background-color: #ec8e8e;
        }
    }

    .mat-icon {
        color: #ffffff;
    }

    input {
        color: #ffffff;
    }

    .mat-input-element {
        caret-color: #ffffff;
    }
}

.mat-menu-item,
button.mat-menu-item {
    height: 36px;
    line-height: 36px;
    font-size: $base_size - 3px;

    mat-icon {
        width: 16px;
        height: 16px;
        font-size: $base_size + 0px;
    }
}

mat-form-field {
    width: $full;
}

mat-cell,
mat-footer-cell,
mat-header-cell {
    line-height: 1.3;
}

.reports_row {
    mat-card {
        @extend .padding_0;
    }

    mat-card-title {
        border-bottom: 1px solid #dedede;
        padding: 0 30px;
        line-height: 60px;
        font-weight: 600;
        font-size: $base_size + 8px;
        color: #333333;
    }
}

.mat-menu-panel {
    min-width: 152px !important;
    max-height: 50vh !important;
}

.add_title {
    label.mat-radio-label {
        color: rgba(0, 0, 0, 0.54);
    }
}

.custom_alert_dialog mat-dialog-container {
    @extend .padding_0;
    border-radius: 2px;
}

.alert_box {
    background: rgba(0, 0, 0, 0.288);
}

@media (max-width: 767px) {
    .bracket_listing {

        .mat-column-AgeRange,
        .mat-column-sport,
        .mat-column-Edit,
        .mat-column-Visibility,
        .mat-column-Delete {
            display: none;
        }
    }
}

@media (max-width: 599px) {

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 70px !important;
    }

    .dashboard_middle {
        height: calc(100vh - 70px);
    }
}

.mat-checkbox-frame {
    border-color: rgba(0, 0, 0, 0.13);
}

.mat-header-row {
    // background: #ebf3f5;
    background: $primary-color;

    .mat-checkbox-frame {
        border-color: rgba(255, 255, 255, 0.87);
    }
}

.mat-header-cell {
    color: rgba(255, 255, 255, 0.87);
}

.mat-header-cell {
    font-size: $base_size + 0px;
    font-weight: 500;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
    border-bottom-color: #d5dde0;
}

mat-row.mat-row:last-child {
    border-bottom-color: rgba(0, 0, 0, 0);
}

.mat-sort-header-arrow {
    color: rgba(255, 255, 255, 0.68);
}

.top_error {
    font-size: $base_size + 0px;
    background: #e6b264;
    margin: 0 0 10px;
    color: $white;
    padding: 2px 10px;
    border-radius: 2px;
}

.mat-ink-bar {
    height: 4px !important;
}

// @media (min-width: 768px) {
//     .bracket_listing .mat-tab-body-content {
//         padding: 20px 0 0;
//     }
// }

@media (max-width: 360px) {
    .table_action_col {
        flex: 0 0 50px !important;
        padding-right: 15px !important;
    }

    .table_col_select {
        flex: 0 0 50px !important;
        padding-left: 15px !important;
    }
}

.custom_bottom_sheet {
    max-height: 100vh !important;
}

.add_icon {
    position: fixed;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.org_select {
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: $cyan-blue;
    }

    .mat-select-placeholder {
        color: $cyan-blue;
    }

    .mat-select-value {
        color: $cyan-blue;
    }

    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: $cyan-blue;
    }

    // .mat-form-field-appearance-outline .mat-form-field-outline-end, .org_select .mat-form-field-appearance-outline .mat-form-field-outline-start {
    //     border-width: 2px;
    // }

    .mat-select-arrow {
        color: $cyan-blue;
    }
}

.listing_middle .mat-tab-header,
.bracket_listing .mat-tab-header,
.import_section .mat-tab-header,
app-failed-contract .mat-tab-header,
app-competition-roster-payment .mat-tab-header,
app-photo-rosters .mat-tab-header,
app-competition-transaction-list .mat-tab-header,
.cheer_roster_tabs .mat-tab-header,
app-system-configuration .mat-tab-header,
app-communication .mat-tab-header,
app-manage-organization .mat-tab-header,
.haitus_con_wrapper .mat-tab-header,
app-manage-report-card .mat-tab-header {
    margin: 0 auto;
    box-shadow: 0px 3px 8px -2px rgba(123, 123, 123, 0.41), 0px 3px 4px 0px rgba(195, 195, 195, 0.05),
        0px 1px 8px 0px rgba(239, 239, 239, 0.09);
    width: $full;
    background: $white;
    z-index: 2;
}

.haitus_con_wrapper .mat-tab-body-wrapper {
    padding: 0 30px;

    @media (max-width: 1199px) {
        padding: 0 15px;
    }
}

.bracket_listing {
    .mat-tab-group {
        height: $full;
    }
}

.mat-tab-nav-bar,
.mat-tab-header {
    border: none;
}

.find_page .mat-paginator-container {
    height: 55px;
}

.upload_document_sheet {
    max-width: 640px !important;
    width: 100vw;
}

.system_configuration {
    svg.mat-datepicker-toggle-default-icon {
        width: 24px !important;
        height: 24px;
    }

    svg.mat-datepicker-toggle-default-icon path+path {
        fill: $cyan-blue;
    }
}

.image_cropper_section.webcam input[type="file"] {
    visibility: hidden;
    width: 0;
}

.mat-fab:not([class*="mat-elevation-z"]) {
    box-shadow: 0 3px 4px -1px rgba(0, 0, 0, 0.45), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.import_section .mat-tab-group,
.certification .mat-tab-group {
    width: $full;
}

.participant_listing {
    .mat-tab-group-dynamic-height .mat-tab-body-content {
        overflow: auto;
    }
}

.certification {
    .mat-tab-group-dynamic-height .mat-tab-body-content {
        overflow: auto;
    }
}

@media (max-width: 767px) {
    .find_page .mat-paginator-container {
        height: inherit;
    }

    .photo_upload_screen {
        .mat-card-header-text {
            width: $full;
        }
    }
}

.payment_details .mat-dialog-container {
    padding: 0;
}

.cropper_canvas {
    width: $full;
}

.certify_dialog .mat-dialog-container {
    @extend .padding_0;
}

.certify_btn_row svg {
    vertical-align: top;
}

.checkbox_list {
    .mat-pseudo-checkbox-checked::after {
        top: 5px;
    }
}

.cleared {
    color: #3dc926 !important;
    border-color: #3dc926 !important;
    // cursor: not-allowed !important;

    .mat-button-focus-overlay {
        background: rgba(61, 201, 38, 0.1) !important;
    }
}

.clearedPhoto {
    color: #3dc926 !important;
    border-color: #3dc926 !important;

    .mat-button-focus-overlay {
        background: rgba(61, 201, 38, 0.1) !important;
    }
}

button:focus {
    outline: 0;
}

.reports_row mat-card {
    border-radius: 4px;
}

mat-paginator {
    background: none;
}

@media (max-width: 767px) {
    .hide_pagination_from_mobile {
        display: none;
    }
}

.addEditPermissions {
    width: 100vw;
    max-width: inherit !important;
    height: 100vh;
    max-height: inherit !important;
    padding: 0px !important;
}

.take_photo .mat-dialog-container,
.resize_photo .mat-dialog-container {
    @extend .padding_0;
}

.participant_listing {
    .mat-tab-group.mat-tab-group-dynamic-height .mat-tab-body.mat-tab-body-active {
        overflow-y: hidden;
        min-height: calc(100vh - 118px);
    }
}

.mat-paginator-container {
    padding: 0 !important;
}

.mat-paginator-page-size {
    margin-right: 0 !important;
}

.mat-paginator-range-label {
    margin: 0 15px 0 15px !important;
}

.mat-paginator-range-label {
    margin: 0 0 0 10px !important;
}

.mat-paginator-page-size-select {
    margin: 6px 0 0 !important;
}

.filter_sidebar {
    flex-wrap: wrap;
    max-width: 280px;
    display: flex;
    padding: 0 15px;

    .search {
        max-width: 250px;
        margin: 0 0 15px;
        width: $full;
    }

    button {
        max-width: 250px;
        margin: 0 0 15px;
        width: $full;
    }
}

.filter_sidebar_container {
    height: $full;
    background: rgba(0, 0, 0, 0);
}

.sidebar_heading {
    font-size: 18px;
    margin: 15px 0 10px;
    width: $full;
}

.slide_panel {
    width: $full;
    overflow: hidden;
    z-index: 2;

    .search {
        display: inline-flex;
        padding-right: 10px;
        width: $full;
    }

    .slide_panel_inner {
        padding: 0 30px;
    }

    .close_search_icon {
        font-size: $base_size + 8px !important;
        vertical-align: initial;
    }
}

mat-button-toggle-group .mat-button-toggle {
    white-space: pre-wrap;
}

@media (max-width: 767px) {
    .slide_panel {
        .slide_panel_inner {
            padding: 0 15px;
        }
    }
}

.certification {
    .mat-drawer-container {
        background-color: rgba(0, 0, 0, 0);
    }
}

.mat-bottom-sheet-container {
    border-radius: 8px 8px 0 0;
}

.theme-green .bs-datepicker-head {
    background-color: $primary-color !important;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: $primary-color;
}

.theme-green .bs-datepicker-body table td.week span {
    color: $primary-color;
}

.custom_range_picker .mat-form-field-wrapper {
    width: $full;
}

.addEditPermissions {
    border-radius: 0px;
}

.mat-pseudo-checkbox {
    color: rgba(0, 0, 0, 0.13) !important;
}

.mat-pseudo-checkbox-checked::after {
    top: 4px;
    left: 2px;
}

.offline_note {
    text-align: center;
    background: red;
    color: $white;
    position: fixed;
    width: $full;
    z-index: 99;
    top: 0;
    left: 0;
    @extend .margin_0;
    font-size: $base_size + 0px;
    @extend .padding_0;
    height: 30px;
    font-weight: 500;
    line-height: 30px;
}

.custom_alert_dialog .mat-dialog-container {
    border-radius: 6px;
}

.reset_system_inner {
    .checkbox_row {
        .mat-checkbox-inner-container {
            margin-right: 16px;
        }
    }
}

.sk-double-bounce .sk-child {
    background-color: #f44336 !important;
}

#spinner {
    background-color: rgba(0, 0, 0, 0) !important;
}

.sk-child.sk-double-bounce2 {
    background-color: #00247c !important;
}

.add_athlete .mat-checkbox-layout {
    white-space: normal !important;
}

.certification_holds_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
        background-color: #4caf50;
        color: white;
    }
}

.add_volunteer_form {
    .mat-checkbox-layout {
        white-space: normal !important;
    }
}

.bs-datepicker-body table td:first-child span {
    font-size: 0 !important;
    color: $white !important;
    width: 0px;
}

.participant_data {
    .mat-tab-body-content {
        padding-bottom: 5px;
    }
}

.ex_holds {
    .mat-card-content {
        padding: 15px !important;
    }
}

.sidebar {
    .mat-expansion-panel {
        background: rgba(0, 0, 0, 0) !important;

        .mat-expansion-panel-header {
            padding: 0 24px 0 16px;
            background: rgba(0, 0, 0, 0) !important;
        }

        .mat-expansion-panel-header-title {
            color: rgba(255, 255, 255, 1);
        }

        .mat-list {
            width: $full;
            @extend .padding_0;
        }
    }

    .mat-expansion-panel:not([class*="mat-elevation-z"]) {
        box-shadow: none;
    }

    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
        color: rgba(255, 255, 255, 0.54);
    }

    .mat-expansion-panel-header-description,
    .mat-expansion-panel-header-title {
        margin-right: 0;
    }

    .mat-expansion-panel-body {
        @extend .padding_0;
    }

    .mat-expansion-panel-spacing {
        @extend .margin_0;
    }
}

.payment_drawer.mat-drawer.mat-drawer-end {
    transform: none !important;
}

app-competition-roster-payment mat-tab-body {
    overflow: hidden !important;
}

@media print {
    .isPrinting>* {
        display: none;
    }

    .isPrinting app-print-roster {
        display: block;
    }
}

.list_checkbox {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 4;

    .mat-checkbox-inner-container {
        width: 36px;
        height: 36px;
        background: rgba(255, 255, 255, 0.36);
        border-radius: $full;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
    }

    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: $full;
    }

    .mat-checkbox-frame {
        border-color: #e85640;
    }

    @media (max-width: 1199px) {
        display: none;
    }
}

.select_all_roster {
    background: $white;
    padding: 0 15px 0 5px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    color: #116285;
    font-family: Nunito, sans-serif;
    font-size: 14px;

    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: $full;
    }

    @media (max-width: 1199px) {
        display: none;
    }
}

@media print {
    .print_col {
        width: 25%;
        display: inline-flex;
        padding: 0 5px 5px;
        box-sizing: border-box;
        overflow: hidden;
        page-break-inside: avoid;
    }
}

.warehouse_holds_col {
    .mat-sort-header-arrow {
        display: none;
    }
}

@media (max-width: 767px) {
    .cheer_category_page {
        .mat-sort-header-arrow {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    app-add-cheer-category mat-checkbox label.mat-checkbox-layout {
        white-space: normal;
    }
}

.webcam_middle video {
    vertical-align: top;
}

.child_row {
    .mat-checkbox-frame {
        background-color: white;
    }
}

.mat-button-toggle {
    color: rgba(21, 98, 133, 0.77);
}

.mat-button-toggle-group,
.mat-button-toggle-standalone {
    border: 1px solid rgb(21, 98, 133);
    box-shadow: none !important;
}

.mat-button-toggle-checked {
    background-color: #156285;
    color: rgba(255, 255, 255, 1);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
    background-color: rgb(21, 98, 133);
}

mat-button-toggle-group.mat-button-toggle-group {
    margin-bottom: 20px;
}

.cheer_transaction_popup .mat-dialog-container,
.move_status_dialog .mat-dialog-container {
    padding: 0;
}

.cheer_transaction_popup .transaction_inner {
    padding: 24px;
}

.move_status_dialog .mat-dialog-container {
    border-radius: 8px;
    background-color: #e6f7f8;
}

.md-drppicker td.active,
.md-drppicker td.available.in-range.active,
.md-drppicker td.active:hover,
.md-drppicker .btn:not(.btn-default),
.md-drppicker .ranges ul li button.active {
    background-color: map-get($theme-primary, "default") !important;
    color: white !important;
}

.add_edit_grades_bottomsheet {
    max-width: 550px !important;
}

@media (min-width: 768px) and (max-width: 1440px) {
    .md-drppicker.double {
        min-width: 500px;
    }
}

.cdk-overlay-pane {
    transform: translateX(0) !important;
}

/* .mat-select-panel:not([class*=mat-elevation-z]) {min-width: 100% !important;}
.mat-select-panel {max-height: 214px !important;} */
.help_video_dailog {
    .mat-dialog-container {
        padding: 0;
    }
}

.mat-select-panel.select_core_course {
    max-height: 245px;
}

.mat-select-panel.select_course_type {
    max-height: 200px;
}

.mat-select-panel.grades_dropdown {
    max-height: 132px;
}

.mat-select-panel.select_course_type_two {
    max-height: 168px;
}

.spring_card_max_lmt {
    max-height: 255px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        height: 7px;
    }

    &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background: #525965;
        border: 0px none #fff;
        border-radius: 0px;

        &:hover {
            background: #525965;
        }

        &:active {
            background: #525965;
        }
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border: 0px none #ffffff;
        border-radius: 50px;

        &:hover {
            background: transparent;
        }

        &:active {
            background: transparent;
        }
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.pad_left31 {
    padding-left: 31px;
    position: relative;

    .mat-checkbox {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.mar_top77 {
    margin-top: 77px !important;
}

.mar_none {
    margin: 0;
}

.width_full {
    width: 100%;
    display: block;
}

.select_organization {
    max-height: 160px !important;
}

app-executive-holsts .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding: 0 15px;
}

app-executive-holsts .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .panel_col:first-child {
    min-width: 140px !important;
}

.region_dropdown {
    max-height: 125px !important;
}

.change_region_dropdown {
    max-height: 125px !important;
}

.permission_group_dropdown {
    max-height: 168px !important;
}

.title_dropdown {
    max-height: 210px !important;
}

.organization_autocomplete .mat-form-field-infix {
    width: 250px !important;
}

.updateVolunteerDailog {
    width: 100%;
    max-width: 700px !important;
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
    .right_content_half .width_half {
        width: 100%;
    }

    .generated_reportcard_wrapper ul li p {
        padding-right: 0;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .generated_reportcard_wrapper ul li p {
        padding-right: 15px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .generated_reportcard_wrapper.pad_lft_none {
        padding-top: 0px;
    }

    .generated_reportcard_wrapper ul.two_part_listing {
        justify-content: flex-end;
    }

    .generated_reportcard_wrapper ul.two_part_listing label {
        text-align: right;
    }

    .generated_reportcard_wrapper ul.two_part_listing li {
        justify-content: flex-end;
    }

    .generated_reportcard_wrapper.pad_top_none ul {
        padding-left: 0px;
    }

    .caption_txt {
        text-align: left;
    }

    .new_data_wrapper .mar_top77 {
        margin-top: 0 !important;
    }

    .right_content.green_content,
    .right_content.red_content {
        text-align: left;
    }

    .card_content {
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .new_data_wrapper .pad_left31 {
        padding-left: 23px;
    }

    .new_data_wrapper .spring_period ul.right_listing li:nth-child(1) {
        width: 25%;
    }

    .new_data_wrapper .spring_period ul.right_listing li:nth-child(2) {
        width: 50%;
    }

    .new_data_wrapper .spring_period ul.right_listing li:nth-child(3) {
        width: 25%;
    }
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #fff !important;
}

.certifiedAthletesLine {
    min-height: 700px !important;
}

.dashboard .mat-expansion-panel-header {
    padding: 0 16px;
}

.dashboard .mat-expansion-panel-body {
    padding: 0 16px 16px;
}

@media (max-width: 767px) {
    .dashboard .mat-expansion-panel-header-title {
        font-size: 1rem;
    }
}

app-dashboard {

    .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
        background-color: #e85640;
    }
}

owl-carousel-o {
    max-height: 210px;

    .owl-stage {
        display: flex;

        .owl-item {
            width: 100% !important;

            img {
                max-height: 210px;
            }
        }
    }

    .owl-nav {
        position: absolute;
        z-index: 99999;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        margin: 0 !important;

        .owl-prev {
            background: #fff !important;
            color: #5f5959 !important;
            width: 30px !important;
            height: 30px !important;
            display: flex !important;
            align-items: center !important;
            flex-wrap: wrap !important;
            justify-content: center !important;
            border-radius: 100% !important;
            cursor: pointer !important;
            margin: 0 !important;
        }

        .owl-next {
            background: #fff !important;
            color: #5f5959 !important;
            width: 30px !important;
            height: 30px !important;
            display: flex !important;
            align-items: center !important;
            flex-wrap: wrap !important;
            justify-content: center !important;
            border-radius: 100% !important;
            cursor: pointer !important;
            margin: 0 !important;
        }
    }
}


.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: rgb(135 178 197);
}

.sidebar {
    .mdc-list-item__content {
        height: 100%;
    }
}


.mdc-text-field--filled {
    height: 49px !important;
}


.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
    --mat-tab-header-with-background-foreground-color: #ccf0ff;
}


.hold_list {
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-primary {
        background-color: #e85640;
    }
}

.mat-toolbar-row {
    color: #ffffff;
}

.mat-mdc-card {
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.mat-mdc-card-title,
.mat-mdc-card-subtitle {
    background: #126385;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #fff;
    display: flex !important;
    font-size: 18px;
    justify-content: space-between;
    line-height: 50px !important;
    margin: 0 0 30px 0 !important;
    padding: 0 20px;
}

.mat-mdc-card-content {
    padding: 20px;
}

.mat-mdc-chip {
    margin-right: 10px;
}

span.filename {
    margin-left: 5px;
}

button.dissociate_btn {
    margin: 0 5px 10px 0px;
}

.mat-mdc-raised-button.mat-accent span {
    color: white;
}

.cheer_brackets .mat-mdc-card-title {
    color: #fff !important;
    font-size: 18px !important;
}

.cheer_brackets .export_btn {
    color: #fff !important;
    margin: 8px 0 0 0 !important;
}

.mat-mdc-menu-content {
    max-height: 80vh;
}


.bottom_sheet_form,
.participant_form {
    .period_heading {
        width: 55%;
        min-width: 220px;
    }

    mat-form-field {
        margin: 0 0 16px;
    }

    .mdc-text-field {
        overflow: inherit;
    }

    .mat-mdc-form-field-error {
        font-size: 13px;
    }

    .mdc-text-field {
        padding: 0;
    }
}

.webcam-wrapper video {
    max-width: 320px;
    max-height: 240px;
}

@media (max-width: 767px) {
    .webcam-wrapper video {
        width: 320px !important;
    }
}

app-locate-athlete-by-city {
    .filter_row {
        .mdc-form-field {
            min-width: 160px;

            .mdc-label {
                white-space: no-wrap;
            }
        }
    }
}

.select_all_roster .mdc-checkbox{height: 16px !important;}